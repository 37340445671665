import React, {useState, useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [isAuth, setIsAuth] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      const url = process.env.REACT_APP_API_URL
          axios.get(`${url}/user/me`,{
            headers: {
              'authorization': `Bearer ${sessionStorage.getItem('accessToken')}`
            }
          })
          .then(function(res) {
            if(res.status === 200)
            {
              sessionStorage.setItem('userProfile',res.data)
              sessionStorage.setItem('email',res.data['username'])
              console.log(res.data['username'])
              setIsAuth(true)
            }

            setLoading(true)
          } ).catch( err => {
            setLoading(true)
          })



        // const loaded = (res) => {
        //     if(res.status === 200){
        //         setIsAuth(true);
        //     }
        //     setLoading(true);
        // }

        // handleApi(authApi.auth, loaded);
    }, []);

    return (
        <React.Fragment>
            {
                loading &&
                <Route
                    {...rest}
                    render={props => (
                        isAuth
                        ? <Component {...props} />
                        : <Redirect to ='/login'/>

                    )}
                />
            }
        </React.Fragment>
    )
}

export default PrivateRoute;
