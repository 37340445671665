import React, { Component, useEffect, useState } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import PrivateRoute from './PrivateRoute';
import axios from 'axios'
import Dashboard from 'src/views/dashboard/Dashboard'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
//const ForgetPassword = React.lazy(() => import('./views/pages/forgetPassword/ForgetPassword'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const ForgetPassword = React.lazy(() => import('./views/pages/forgetPassword/ForgetPassword'));
function App () {

    return (

      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>

            <Route exact path="/forgetPassword" name="Forget Password Page" render={props => <ForgetPassword {...props}/>} />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} /> */}
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
               <PrivateRoute path="/" name="Home" component ={TheLayout} />
               {/* <Route path="/" name="Home" render={props => <TheLayout {...props}/>} /> */}
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
}

export default App;
